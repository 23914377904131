/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/


import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function ImgSlider() {

    let settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    }

    return (
        <Carousel {...settings}>
            <Wrap>
                <img src="/images/sliders/spark-story.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/starwars-visions.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/spidey-friends.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/what-if.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/turner-hooch.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/the-simpsons.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/shang-chi.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/owl-house.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/onward.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/future-president.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/cruella.jpg" />
            </Wrap>
            <Wrap>
                <img src="/images/sliders/chip-dale.jpg" />
            </Wrap>
        </Carousel>
    )
}

export default ImgSlider


const Carousel = styled(Slider)`
    margin-top: 20px;

    ul li button {
        &:before {
            font-size: 10px;
            color: rgb(150, 158, 171);
        }
    }   

    li.slick-active button::before {
        color: rgb(150, 158, 171);
    }

    .slick-list {
        overflow: visible;
    }

    ul.slick-dots {
        position: absolute;
        bottom: 10px;
        align-text: right;
        left: 500px;
    }

    button {
        z-index: 1;
    }

`

const Wrap = styled.div`
    cursor: pointer;
    img {
        border: 4px solid transparent;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
        rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        transition-duration: 300ms;

        &:hover {
            border: 4px solid rgba(248, 249, 249, 0.8);

        }


    }
`