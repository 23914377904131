/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/

import React from 'react'
import styled from 'styled-components'

function Movies() {
    return (
        <Container>
            <h4>Recommended for You</h4>
            <Content>
                <Wrap>
                    <a href="/loki"><img src="/images/movies/loki/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                  <a href="/luca"><img src="/images/movies/luca/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                   <a href="/home-alone"><img src="/images/movies/home-alone/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                   <a href="/wall-e"><img src="/images/movies/wall-e/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                   <a href="/dinosaurs"><img src="/images/movies/dinosaurs/icon.jpg" /></a>
                </Wrap>
                </Content>
                    <h4>Demo Built By Brian McCracken</h4>
                <Content>
                <Wrap>
                    <a href="/black-widow"><img src="/images/movies/blackwidow/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                    <a href="/endgame"><img src="/images/movies/endgame/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                    <a href="/falcon-soldier"><img src="/images/movies/falcon-soldier/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                    <a href="/theking"><img src="/images/movies/theking/icon.jpg" /></a>
                </Wrap>
                <Wrap>
                    <a href="/whatif"><img src="/images/movies/whatif/icon.jpg" /></a>
                </Wrap>
            </Content>
            <h4>Notes:</h4>
            <p>This is a demo that is built to roughly 65% completion at this point in time. As time allows, I will continue to iterate the demo foward.</p>
            <p>I'm currently seek a new role as a general front-end developer, or more specifically, a ReactJS developer.</p>
            <p><a href="mailto:brian@brianmccracken.com">Reach Out</a> if you're interested in producing these types of user interfaces for your projects.</p>
        </Container>
    )
}

export default Movies

const Container = styled.div`
    padding-bottom: 80px;

`

const Content = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(5, minmax(0,1fr));

`

const Wrap = styled.div`
    border: 3px solid rgba(249, 249, 249, .01);
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    cursor: pointer;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        box-shadow: rgb(0 0 0 / 80%) 0px 40px 55px -16px,
        transform: scale(1.05);
        border-color: rgba(249, 249, 249, 0.8);
    }

`