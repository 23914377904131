/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/

import React from 'react';
import { Counter } from './features/counter/Counter';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Detail from './components/Detail';
import Login from './components/Login';
import Loki from './pages/Loki';
import Luca from './pages/Luca';
import Homealone from './pages/Homealone';
import Walle from './pages/Wall-e';
import Dinosaurs from './pages/Dinosuars';
import Blackwidow from './pages/Blackwidow';
import Endgame from './pages/Endgame';
import Falconsoldier from './pages/Falcon-soldier';
import Theking from './pages/Theking';
import Whatif from './pages/Whatif';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
          <Header />
        <Switch>
        <Route path="/whatif">
            <Whatif />
          </Route>
        <Route path="/theking">
            <Theking />
          </Route>
        <Route path="/falcon-soldier">
            <Falconsoldier />
          </Route>
        <Route path="/endgame">
            <Endgame />
          </Route>
           <Route path="/black-widow">
            <Blackwidow />
          </Route>
           <Route path="/dinosaurs">
            <Dinosaurs />
          </Route>
          <Route path="/wall-e">
            <Walle />
          </Route>
           <Route path="/home-alone">
            <Homealone />
          </Route>
          <Route path="/loki">
            <Loki />
          </Route>
          <Route path="/luca">
            <Luca />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
