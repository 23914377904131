/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/

import React from 'react'
import styled from 'styled-components'

function header() {
    return (
        <NavContainer>          
                <a href="/home"><Logo src="/images/logo.svg" /></a>      
            <NavMenu>           
                <a href="/home">
                    <img src="/images/home-icon.svg" />
                    <span>HOME</span>
                </a>
                <a>
                    <img src="/images/search-icon.svg" />
                    <span>SEARCH</span>
                </a>
                <a>
                    <img src="/images/watchlist-icon.svg" />
                    <span>WATCH LIST</span>
                </a>
                <a>
                    <img src="/images/original-icon.svg" />
                    <span>ORIGINALS</span>
                </a>
                <a>
                    <img src="/images/movie-icon.svg" />
                    <span>MOVIES</span>
                </a>
                <a>
                    <img src="/images/series-icon.svg" />
                    <span>SERIES</span>
                </a>
            </NavMenu>
                <a href="https://www.brianmccracken.com"><User src="/images/top-signature.jpg" /></a>
      
        </NavContainer>         
    )
}

export default header

const NavContainer = styled.nav`
    display: flex;
    align-items: center;
    padding: 0 40px;
    height: 70px;
    background-color: #090b13;
    overflow-x: hidden;
`
const Logo = styled.img`
    width: 80px;
`

const NavMenu = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 30px;
    a {
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        color: white;
        text-decoration: none;
        
        img {
            height: 22px;
        }

        span {
            position: relative;
            font-size: 14px;
            letter-spacing: 1.4px;

            &:after {
                content:"";
                height: 2px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                background-color: white;
                opacity: 0;
                transform: scaleX(0);
                transform-origin: left center;
                transition: all 250ms cubic-bezier(0.25, 0.4, 0.45, 0.9) 0s;
            }
        }

        &:hover {
            span:after {
                opacity: 1;
                transform: scaleX(1);
            }
        }        

    }
`

const User = styled.img`
    width: 288px;
    height: 45px;
    cursor: pointer;
`