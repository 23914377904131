/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/

import React from 'react'
import styled from 'styled-components'
import ImgSlider from './ImgSlider'
import Viewers from './Viewers'
import Movies from './Movies'

function Home() {

    

    return (
        <Container>
            <ImgSlider />
            <Viewers />
            <Movies />
        </Container>
    )
}

export default Home

const Container = styled.main`
    min-height: calc(100vh - 70px);
    padding: 0 calc(3.5vw + 5px);
    position: relative;
    overflow-x: hidden;
    
    &:before {
        background: url("/images/home-background.png") center center / cover no-repeat fixed;
        content: "";
        position: absolute;
        top 0;
        left: 0;
        right 0;
        bottom: 0;
        z-index: -1;
    }

`
