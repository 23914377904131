/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/

import React from 'react'
import styled from 'styled-components'

function Homealone() {
    return (
        <Container>
            <Background>
                <img src="/images/movies/endgame/background.jpg"></img>
            </Background>
            <ImageTitle>
                <img src="/images/movies/endgame/title.png" />
            </ImageTitle>
            <Controls>
                <PlayButton>
                    <img src="/images/play-icon-black.png" />
                    <span>PLAY</span>
                </PlayButton>
                <TrailerButton>
                    <img src="/images/play-icon-white.png" />
                    <span>TRAILER</span>
                </TrailerButton>
                <AddButton>
                    <span>+</span>
                </AddButton>
                <GroupWatch>
                    <img src="/images/group-icon.png" />
                </GroupWatch>
            </Controls>
            <SubTitle>
                Science Fiction, Fantasy, Super Hero, Action-Adventure
            </SubTitle>
            <Description>
                The epic conclusion to the Infinity Saga that became a critically acclaimed worldwide phenomenon, this dramatic showdown pits the Avengers against Thanos. After devastating events wiped out half the world’s population and fractured their ranks, the remaining heroes struggle to move forward. But they must come together to restore order and harmony in the universe and bring their loved ones back.
            </Description>    
        </Container>
    )
}

export default Homealone

const Container = styled.div`
    min-height: calc(100vh - 70px);
    padding: 0 calc(3.5vw + 5px);
    position: relative;
    background: linear-gradient(to right, rgb(26,29,41, .9),rgb(26,29,41, .05));
`

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0.8;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

`

const ImageTitle = styled.div`
    height: 30vh;
    min-height: 170px;
    width: 35vw;
    min-width: 200px;
    padding-top: 40px;
    margin-bottom: 60px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;

        }

`

const Controls = styled.div`
    display: flex;
    align-items: center;

`

const PlayButton = styled.button`
    display: flex;
    align-items: center;
    padding: 0px 24px;
    margin-right: 22px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 1.8px;
    height: 56px;
    background-color: rgb(249, 249, 249);
    cursor: pointer;

    &:hover {
        background-color: rgb(198, 198, 198)
    }

`

const TrailerButton = styled(PlayButton)`
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgb(249, 249, 249);
    color: white;


`

const AddButton = styled.button`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 16px;

    span {
        font-size: 30px;
        color: white;
    }
`

const GroupWatch = styled(AddButton)`
    background-color: rgb(0, 0, 0);



`

const SubTitle = styled.div`
    color: rgb(249, 249, 249);
    font-size: 15px;
    min1height: 20px;
    margin-top: 26px;

`

const Description = styled.div`
    color: rgb(249, 249, 249);
    font-size: 20px;
    line-height: 1.4;
    margin-top: 16px;
    width: 50%;
`
