/*
*
* This is a demo of the Disney Plus User Interface
* rebuilt in ReactJS by Brian McCracken
* If you see this code or file elsewhere, please let me
* know at https://www.brianmccracken.com
*
* Naturally, all of the images and characters portayed in
* this demo project belong to Disney. :)
*
*/


import React from 'react'
import styled from 'styled-components'

function Login() {
    return (
        <Container>
            <Content>
                <LogoOne src="/images/cta-logo-one.svg" />
                <SignUp>
                    <a href="/home">ENTER THE APP DEMO</a>
                </SignUp>
                <StreamNow>
                    Stream now. - <b>Terms Apply.</b>
                </StreamNow>
                <LogoTwo src="/images/cta-logo-two.png" />
                <DisneyOnly>
                    Sign up for Disney+ only.
                </DisneyOnly>
            </Content>
        </Container>
    )
}

export default Login



const Container = styled.div`
    postiion: relative;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: "";
        background-postiion: top;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/images/login-background.jpg");
        z-index: -1;      
    }
`

const Content = styled.div`
    width: 75%;
    max-width: 650px;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;

`

const LogoOne = styled.img`

`
const LogoTwo = styled.img`
    margin-top: 15px;
`

const StreamNow = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: white;
    opacity: 0.7;
`

const DisneyOnly = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: white;
    opacity: 0.7;
    font-weight: 600;
    lett-spacing: 1.4;
`

const SignUp = styled.a`
    width: 100%;
    background-color: #0063e5;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    padding: 12px 0px;
    color: #f9f9f9;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;

    a {
        color: white;
        text-decoration: none;
    }

    &:hover {
        background-color: #0483ee;
    }
`